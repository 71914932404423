/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './navigation/header';
import Footer from './footer/footer';

import { GlobalStyle } from '../theme/global-style';

const fullScreenPaths = ['/director-setup/', '/director-setup'];
const simpleFooterPaths = ['/release', '/release/'];

const containsStringNotAtEnd = (url, string) => {
  // Find the index of 'release/' in the string
  const index = url.indexOf(string);

  // Check if string is found and it is not at the end of the string
  // The substring after string should have at least one more character
  return index !== -1 && index + string.length < url.length;
};

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  if (
    fullScreenPaths.includes(children.props.location.pathname) ||
    containsStringNotAtEnd(children.props.location.pathname, 'release/')
  ) {
    return <>{children}</>;
  }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <GlobalStyle />
      <main>{children}</main>
      <Footer
        simple={simpleFooterPaths.includes(children.props.location.pathname)}
      />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
