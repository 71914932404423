import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Link, navigate } from 'gatsby';
import PropTypes from 'prop-types';

// Component Imports
import Button from '../buttons';
import BottomCut from '../bottomCut';
import Spacer from '../spacer';

// Asset Imports
import DuckSVG from '../../images/rubberduck-logo.inline.svg';
import Stripes01SVG from '../../images/stripes/stripes01.inline.svg';
import Stripes06SVG from '../../images/stripes/stripes06.inline.svg';
import { ButtonText } from '../../theme/typography';

// Function Imports
import toSentenceCase from '../../helpers/toSentenceCase';

// Styled Components
const MobileNavContainer = styled.div`
  --mobileNavContentHeight: 750;
  --mobileNavMaxContentHeight: 750;
  --transitionTiming: 0.3s;
  --navShadowMargin: 64px;

  position: absolute;
  z-index: 99; //create stacking context
  right: 0;
  top: 0;

  margin-top: 15px; //1px less to cover RD Logotype
  margin-right: 16px;
  width: calc(100% - 32px);
  max-width: 400px;
  height: calc(
    calc(var(--mobileNavMaxContentHeight) * 1px) + var(--navShadowMargin)
  );

  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px -20px 25px -25px rgba(0, 0, 0, 0.22);

  transition: transform 0.5s ease;
  transform: ${({ $isMobileMenuOpen }) =>
    $isMobileMenuOpen ? '' : 'translateY(-1000px)'};
`;

const NavPositioning = css`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  border-radius: 5px;
`;

const MobileNavBackground = styled.div`
  ${NavPositioning}
  --baseHeight: 250;
  height: calc(var(--baseHeight) * 1px);
`;

const PrimaryNavBackground = styled.div`
  background: var(--blue6);
  border-radius: 5px;

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  z-index: 0;

  transition: transform var(--transitionTiming) ease-in-out;
  transform-origin: 0 0;
  transform: scaleY(calc(var(--mobileNavContentHeight) / var(--baseHeight)));
`;

const ProductsNavBackground = styled.div`
  background: var(--blue1);
  border-radius: 5px;

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  z-index: 2;

  transition: transform var(--transitionTiming) ease-in-out;
  transform-origin: 0 0;
  transform: ${({ $activeMobileMenu, $name }) =>
    $activeMobileMenu == $name
      ? 'scaleY(calc(var(--mobileNavContentHeight) / var(--baseHeight)))'
      : 'scaleY(calc(var(--mobileNavContentHeight) / var(--baseHeight))) translateX(110%)'};
`;

const SolutionsNavBackground = styled.div`
  background: var(--blue1);
  border-radius: 5px;

  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;

  z-index: 2;

  transition: transform var(--transitionTiming) ease-in-out;
  transform-origin: 0 0;
  transform: ${({ $activeMobileMenu, $name }) =>
    $activeMobileMenu == $name
      ? 'scaleY(calc(var(--mobileNavContentHeight) / var(--baseHeight)))'
      : 'scaleY(calc(var(--mobileNavContentHeight) / var(--baseHeight))) translateX(110%)'};
`;

const MobileNavContent = styled.div`
  ${NavPositioning}
  height: calc(var(--mobileNavMaxContentHeight) * 1px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const MobileNavHeader = styled.div`
  z-index: 9;
  position: relative;
  background: var(--blue6);
  padding: 32px 32px 16px 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Duck = styled(DuckSVG)`
  height: 24px;
  width: auto;
  cursor: pointer;

  transition: all 0.3s ease-in;

  transform: ${({ $activeMobileMenu }) =>
    $activeMobileMenu == 'Primary' ? '' : 'translateX(-15px)'};

  opacity: ${({ $activeMobileMenu }) =>
    $activeMobileMenu == 'Primary' ? 1 : 0};
`;

const BackButton = styled.div`
  position: absolute;
  left: 32px;
  top: 32px;
  display: flex;
  justify-content: center;
  cursor: ${({ $activeMobileMenu }) =>
    $activeMobileMenu == 'Primary' ? '' : 'pointer'};
  pointer-events: ${({ $activeMobileMenu }) =>
    $activeMobileMenu == 'Primary' ? 'none' : ''};

  transition: all 0.3s ease-out;

  transform: ${({ $activeMobileMenu }) =>
    $activeMobileMenu == 'Primary' ? 'translateX(15px)' : ''};

  opacity: ${({ $activeMobileMenu }) =>
    $activeMobileMenu == 'Primary' ? 0 : 1};
`;

const BackIcon = styled.svg`
  fill: none;
  stroke: var(--blue2);
  stroke-width: 2px;
  stroke-linecap: round;
  width: 24px;
  height: 24px;
  transition: stroke 0.2s;
`;

const BackLabel = styled(ButtonText)`
  color: var(--blue2);
  padding-top: 1px;
  margin-left: 4px;
`;

const CloseButton = styled.svg`
  stroke: var(--blue1);
  stroke-width: 2px;
  stroke-linecap: round;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: stroke 0.2s;
`;

const HeaderCut = styled(BottomCut)`
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  transform: scale(-1, -1);
  height: 32px;
  > svg {
    fill: var(--blue6);
  }
`;

const MobileNavSection = styled.div`
  --navHeadingColor: var(--accent);
  --navLabelColor: var(--blue1);
  --navSubtitleColor: var(--blue3);
  --navIconColor: var(--blue3);
  --navIconSize: 24px;
  --navIconMargin: 16px;
  --dividerColor: var(--blue5);

  padding: 0px 32px;
  padding-top: 128px; //Header height + 24px padding

  border-radius: 15px;

  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  transition: transform var(--transitionTiming) ease-in-out;
`;

const PrimaryNavSection = styled(MobileNavSection)`
  z-index: 1;
  transform: ${({ $activeMobileMenu, $name }) =>
    $activeMobileMenu == $name ? '' : 'translateX(-30%)'};
`;

const PrimaryNavSectionStripes = styled(Stripes01SVG)`
  position: absolute;
  width: 200px;
  height: 200px;
  bottom: 250px;
  right: -30px;
  transform: ${({ $activeMobileMenu, $name }) =>
    $activeMobileMenu == $name ? '' : 'translateX(250px) translateY(-250px)'};

  transition: transform 0.5s ease-out;

  > path:nth-child(3) {
    fill: var(--blue3);
  }
`;

const PrimaryNavSectionLayout = styled.ul`
  --listGap: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--listGap);
`;

const ProductsNavSectionLayout = styled.div``;

const ProductsNavSection = styled(MobileNavSection)`
  --navLabelColor: var(--white);
  --navIconColor: var(--blue4);
  --dividerColor: var(--blue3);

  z-index: 3;

  transform: ${({ $activeMobileMenu, $name }) =>
    $activeMobileMenu == $name ? '' : 'translateX(110%)'};
`;

const ProductsNavSectionStripes = styled(Stripes06SVG)`
  position: absolute;
  width: 150px;
  height: 200px;
  top: calc(calc(var(--mobileNavMaxContentHeight) * 1px) - 300px);
  right: -80px;

  transform: ${({ $activeMobileMenu, $name }) =>
    $activeMobileMenu == $name ? '' : 'translateX(250px) translateY(-250px)'};

  transition: transform 0.5s ease-out;

  > path:nth-child(2) {
    fill: var(--accent) !important;
  }
`;

const NavSectionList = styled.ul`
  --listGap: 8px;
  display: flex;
  flex-direction: column;
  gap: var(--listGap);
`;

const SolutionsNavSectionLayout = styled.div``;

const SolutionsNavSection = styled(MobileNavSection)`
  --navLabelColor: var(--white);
  --navIconColor: var(--blue4);
  --navIconSize: 16px;
  --navIconMargin: 8px;
  --dividerColor: var(--blue3);

  z-index: 3;

  transform: ${({ $activeMobileMenu, $name }) =>
    $activeMobileMenu == $name ? '' : 'translateX(110%)'};
`;

const SolutionsNavSectionStripes = styled(Stripes06SVG)`
  position: absolute;
  width: 150px;
  height: 200px;
  top: 75px;
  right: -30px;

  transform: ${({ $activeMobileMenu, $name }) =>
    $activeMobileMenu == $name ? '' : 'translateX(250px) translateY(-250px)'};

  transition: transform 0.5s ease-out;
`;

const MobileNavHeading = styled(ButtonText)`
  color: var(--navHeadingColor);
  margin-bottom: 16px;
`;

const MobileNavItemContainer = styled.li`
  display: flex;
  flex-direction: column;
  gap: var(--listGap);
`;

const MobileNavItem = styled.div``;

const MobileNavDivider = styled.hr`
  border: none;
  border-top: 1px dashed var(--dividerColor);
  width: 100%;
  height: 1px;
`;

const MobileNavItemIconContainer = styled.div`
  width: var(--navIconSize);
  height: var(--navIconSize);
  margin-right: var(--navIconMargin);
  flex: 0 0 auto;
`;

const MobileNavItemIcon = styled.svg`
  stroke: var(--navIconColor);
  stroke-width: 2px;
  stroke-linecap: round;
  fill: none;
  width: 100%;
  height: 100%;
  transition: stroke 0.2s;
`;

const MobileNavItemIconSecondary = styled.svg`
  stroke: none;
  stroke-width: 2px;
  stroke-linecap: round;
  fill: var(--navIconColor);
  width: 100%;
  height: 100%;
  transition: fill 0.2s;
`;

const MobileNavItemLink = styled(Link)`
  display: inline-flex;

  transition: opacity 0.1s ease;

  &:hover {
    .HoverArrow__linePath {
      opacity: 1;
    }

    .HoverArrow__tipPath {
      transform: translateX(3px);
    }

    --navIconColor: var(--accent);
  }
`;

const MobileNavButton = styled(Button)`
  color: var(--navLabelColor);
`;

const MobileNavItemLabelContainer = styled.span`
  display: flex;
  flex-direction: column;
`;

const MobileNavItemLabel = styled(Button)`
  color: var(--navLabelColor);
  padding: 0;
  line-height: 13px;
  margin-top: 3px; // To correct font error
  text-transform: none;
  text-align: left;
  border-radius: 0;
`;

const MobileNavItemSubtitle = styled.span`
  font-family: Proxima Nova;
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-technical-small);
  color: var(--blue5);
  line-height: 20px;
  letter-spacing: 0.03em;
  margin-top: 4px;
`;

const MobileNavFooter = styled.div`
  ${NavPositioning}
  overflow: visible; // For cut
  border-radius: 0 0 5px 5px;

  z-index: 9;
  background: var(--white);
  padding: 16px 32px 24px 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  transition: transform var(--transitionTiming) ease-in-out;
  transform: translateY(calc((var(--mobileNavContentHeight) * 1px) - 71px));

  box-shadow: 0px 20px 25px -15px rgba(0, 0, 0, 0.22);
`;

const FooterCut = styled(BottomCut)`
  position: absolute;
  bottom: 99%;
  left: 0;
  height: 32px;
  > svg {
    fill: var(--white);
  }
`;
// End Styled Components

const heights = {
  Primary: 700,
  Products: 0,
  Solutions: 0,
  Max: 0,
};

const customSort = (arr, desiredOrder) => {
  const orderForIndexVals = desiredOrder.slice(0).reverse();
  return arr.sort((a, b) => {
    const aIndex = -orderForIndexVals.indexOf(a.productTag.slug.current);
    const bIndex = -orderForIndexVals.indexOf(b.productTag.slug.current);
    return aIndex - bIndex;
  });
};

const SiteHeaderMobileNav = ({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  routes,
  productRoutes,
  solutionRoutes,
}) => {
  const [activeMobileMenu, setActiveMobileMenu] = useState('Primary');

  const mobileNavContainer = useRef();
  const productsNavSectionLayout = useRef();
  const solutionsNavSectionLayout = useRef();

  //* On site load → calculate heights & max content height
  useEffect(() => {
    heights['Products'] =
      productsNavSectionLayout.current.offsetHeight + 128 * 2;
    heights['Solutions'] =
      solutionsNavSectionLayout.current.offsetHeight + 128 * 2;

    heights['Max'] = Math.max(
      heights['Primary'],
      heights['Products'],
      heights['Solutions'],
    );
  }, []);

  //* Every time active menu changed, update content height
  useEffect(() => {
    mobileNavContainer.current.style = `--mobileNavContentHeight: ${heights[activeMobileMenu]}; --mobileNavMaxContentHeight: ${heights['Max']}`;
  }, [activeMobileMenu]);

  return (
    <MobileNavContainer
      $isMobileMenuOpen={isMobileMenuOpen}
      ref={mobileNavContainer}
    >
      <MobileNavContent>
        <MobileNavHeader>
          <Duck
            $activeMobileMenu={activeMobileMenu}
            $isMobileMenuOpen={isMobileMenuOpen}
            onClick={() => {
              navigate('/');
              setActiveMobileMenu('Primary');
              setIsMobileMenuOpen(false);
            }}
          />
          <BackButton
            $activeMobileMenu={activeMobileMenu}
            onClick={() => setActiveMobileMenu('Primary')}
          >
            <BackIcon>
              <use href={`/icons/feather-sprite.svg#chevrons-left`} />
            </BackIcon>
            <BackLabel>Back</BackLabel>
          </BackButton>
          <CloseButton
            onClick={() => {
              setIsMobileMenuOpen(false);
              setTimeout(() => setActiveMobileMenu('Primary'), 300);
            }}
          >
            <use href={`/icons/feather-sprite.svg#x`} />
          </CloseButton>
          <HeaderCut />
        </MobileNavHeader>
        <PrimaryNavSection $name='Primary' $activeMobileMenu={activeMobileMenu}>
          <PrimaryNavSectionLayout>
            {routes.map((route, index) => {
              return (
                <MobileNavItemContainer key={index}>
                  <MobileNavItem
                    onClick={
                      route.subMenu
                        ? () => setActiveMobileMenu(route.title)
                        : () => setIsMobileMenuOpen(false)
                    }
                  >
                    <MobileNavItemLink
                      to={!route.subMenu && route.path ? route.path : undefined}
                      as={route.subMenu ? 'span' : undefined}
                    >
                      <MobileNavButton
                        type={'link'}
                        label={route.title}
                        arrow
                      />
                    </MobileNavItemLink>
                  </MobileNavItem>
                  <MobileNavDivider />
                </MobileNavItemContainer>
              );
            })}
            <MobileNavItem key={99} onClick={() => setIsMobileMenuOpen(false)}>
              <MobileNavItemLink to={'/contact'}>
                <MobileNavButton type={'link'} label={'Contact Us'} arrow />
              </MobileNavItemLink>
            </MobileNavItem>
          </PrimaryNavSectionLayout>
          <PrimaryNavSectionStripes
            $name='Primary'
            $activeMobileMenu={activeMobileMenu}
          />
        </PrimaryNavSection>
        <ProductsNavSection
          $name='Products'
          $activeMobileMenu={activeMobileMenu}
        >
          <ProductsNavSectionLayout ref={productsNavSectionLayout}>
            <MobileNavHeading>Video Production</MobileNavHeading>
            <NavSectionList>
              {customSort(productRoutes.nodes, [
                'tv-commercial',
                'corporate',
              ]).map(({ productTag }, index) => (
                <MobileNavItemContainer key={index}>
                  <MobileNavItem>
                    <MobileNavItemLink
                      to={`/products/${productTag.slug.current}`}
                      onClick={() => {
                        setIsMobileMenuOpen(false);
                        setTimeout(() => setActiveMobileMenu('Primary'), 300);
                      }}
                    >
                      <MobileNavItemIconContainer>
                        <MobileNavItemIcon>
                          <use
                            href={`/icons/feather-sprite.svg#${productTag.icon.name}`}
                          />
                        </MobileNavItemIcon>
                      </MobileNavItemIconContainer>
                      <MobileNavItemLabelContainer>
                        <MobileNavItemLabel
                          type={'link'}
                          label={productTag.label}
                          arrow
                        />
                        <MobileNavItemSubtitle>
                          {toSentenceCase(productTag.tagline)}
                        </MobileNavItemSubtitle>
                      </MobileNavItemLabelContainer>
                    </MobileNavItemLink>
                  </MobileNavItem>
                  <MobileNavDivider />
                </MobileNavItemContainer>
              ))}
            </NavSectionList>
            <Spacer size={48} />
            {/* <MobileNavHeading>Other Services</MobileNavHeading>
            <ProductsNavList>
              <MobileNavItem>
                <MobileNavItemLink>
                  <MobileNavItemIconContainer>
                    <MobileNavItemIcon>
                      <use href={`/icons/feather-sprite.svg#scissors`} />
                    </MobileNavItemIcon>
                  </MobileNavItemIconContainer>
                  <MobileNavItemLabelContainer>
                    <MobileNavItemLabel type={'link'} label={'Home'} arrow />
                    <MobileNavItemSubtitle>Testing 123</MobileNavItemSubtitle>
                  </MobileNavItemLabelContainer>
                </MobileNavItemLink>
              </MobileNavItem>
            </ProductsNavList>
            <Spacer size={48} /> */}
          </ProductsNavSectionLayout>
          <ProductsNavSectionStripes
            $name='Products'
            $activeMobileMenu={activeMobileMenu}
          />
        </ProductsNavSection>
        <SolutionsNavSection
          $name='Solutions'
          $activeMobileMenu={activeMobileMenu}
        >
          <SolutionsNavSectionLayout ref={solutionsNavSectionLayout}>
            <MobileNavHeading>By Industry</MobileNavHeading>
            <NavSectionList>
              {solutionRoutes.nodes.map(({ industryTag }, index) => (
                <MobileNavItemContainer key={index}>
                  <MobileNavItem>
                    <MobileNavItemLink
                      to={`/industry/${industryTag.slug.current}`}
                      onClick={() => {
                        setIsMobileMenuOpen(false);
                        setTimeout(() => setActiveMobileMenu('Primary'), 300);
                      }}
                    >
                      <MobileNavItemIconContainer>
                        <MobileNavItemIconSecondary
                          dangerouslySetInnerHTML={{
                            __html: industryTag.svgLogo,
                          }}
                        />
                      </MobileNavItemIconContainer>
                      <MobileNavItemLabel
                        type={'link'}
                        label={industryTag.label}
                        arrow
                      />
                    </MobileNavItemLink>
                  </MobileNavItem>
                  <MobileNavDivider />
                </MobileNavItemContainer>
              ))}
            </NavSectionList>
            <Spacer size={48} />
            {/* <MobileNavHeading>By Company Type</MobileNavHeading>
            <ProductsNavList>
              <MobileNavItem>
                <MobileNavItemLink>
                  <MobileNavItemIconContainer>
                    <MobileNavItemIcon>
                      <use href={`/icons/feather-sprite.svg#scissors`} />
                    </MobileNavItemIcon>
                  </MobileNavItemIconContainer>
                  <MobileNavItemLabelContainer>
                    <MobileNavItemLabel type={'link'} label={'Home'} arrow />
                    <MobileNavItemSubtitle>Testing 123</MobileNavItemSubtitle>
                  </MobileNavItemLabelContainer>
                </MobileNavItemLink>
              </MobileNavItem>
              <MobileNavDivider />
              <MobileNavItem>
                <MobileNavItemLink>
                  <MobileNavItemIconContainer>
                    <MobileNavItemIcon>
                      <use href={`/icons/feather-sprite.svg#power`} />
                    </MobileNavItemIcon>
                  </MobileNavItemIconContainer>
                  <MobileNavItemLabelContainer>
                    <MobileNavItemLabel type={'link'} label={'Home'} arrow />
                    <MobileNavItemSubtitle>Testing 123</MobileNavItemSubtitle>
                  </MobileNavItemLabelContainer>
                </MobileNavItemLink>
              </MobileNavItem>
              <MobileNavDivider />
              <MobileNavItem>
                <MobileNavItemLink>
                  <MobileNavItemIconContainer>
                    <MobileNavItemIcon>
                      <use href={`/icons/feather-sprite.svg#pie-chart`} />
                    </MobileNavItemIcon>
                  </MobileNavItemIconContainer>
                  <MobileNavItemLabelContainer>
                    <MobileNavItemLabel type={'link'} label={'Home'} arrow />
                    <MobileNavItemSubtitle>Testing 123</MobileNavItemSubtitle>
                  </MobileNavItemLabelContainer>
                </MobileNavItemLink>
              </MobileNavItem>
            </ProductsNavList>
            <Spacer size={48} /> */}
          </SolutionsNavSectionLayout>
          <SolutionsNavSectionStripes
            $name='Solutions'
            $activeMobileMenu={activeMobileMenu}
          />
        </SolutionsNavSection>
      </MobileNavContent>
      <MobileNavBackground>
        <PrimaryNavBackground
          $name='Primary'
          $activeMobileMenu={activeMobileMenu}
        />
        <ProductsNavBackground
          $name='Products'
          $activeMobileMenu={activeMobileMenu}
        />
        <SolutionsNavBackground
          $name='Solutions'
          $activeMobileMenu={activeMobileMenu}
        />
      </MobileNavBackground>
      <MobileNavFooter>
        <Button
          type={'primary'}
          label={'Contact Us'}
          path={'/contact'}
          onClick={() => {
            setIsMobileMenuOpen(false);
            setTimeout(() => setActiveMobileMenu('Primary'), 300);
          }}
        />
        <FooterCut />
      </MobileNavFooter>
    </MobileNavContainer>
  );
};

SiteHeaderMobileNav.propTypes = {
  isMobileMenuOpen: PropTypes.bool.isRequired,
  setIsMobileMenuOpen: PropTypes.func.isRequired,
};

export default SiteHeaderMobileNav;
