import styled from 'styled-components';

//** HEADERS **/
export const SuperHeader = styled.h2`
  font-family: Poppins;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-SH);
  line-height: 64px;
  letter-spacing: -0.02em;

  @media (min-width: 750px) {
    line-height: 88px;
    letter-spacing: -0.01em;
  }

  @media (min-width: 1200px) {
    line-height: 104px;
  }
`;

export const D1 = styled.h2`
  font-family: Poppins;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-D1);
  line-height: 56px;
  letter-spacing: 0em;

  @media (min-width: 750px) {
    line-height: 72px;
  }
`;

export const D2 = styled.h3`
  font-family: Poppins;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-D2);
  line-height: 48px;
  letter-spacing: 0em;

  @media (min-width: 750px) {
    line-height: 56px;
  }
`;

//TODO: Unsure on semantic elements here -> should D3 be H3 or 4, preheader the same q?
export const D3 = styled.h4`
  font-family: Poppins;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-D3);
  line-height: 32px;
  letter-spacing: 0em;
`;

export const HeroPreHeader = styled.h1`
  font-family: Poppins;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-PH);
  line-height: 32px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const PreHeader = styled.h2`
  font-family: Poppins;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-PH);
  line-height: 32px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const ButtonText = styled.p`
  font-family: Poppins;
  font-weight: var(--font-weight-semibold);
  font-size: var(--font-size-button);
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

//TODO: This isnt used in nav ATM (used elsewhere though)
export const NavLabel = styled.p`
  font-family: Poppins;
  font-weight: var(--font-weight-semibold);
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
`;

//** BODY TEXT **//
export const P1 = styled.p`
  font-family: Proxima Nova;
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-P1);
  line-height: 24px;
  letter-spacing: 0em;

  @media (min-width: 700px) {
    line-height: 32px;
  }
`;

export const P2 = styled.p`
  font-family: Proxima Nova;
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-P2);
  line-height: 24px;
  letter-spacing: 0em;
`;

export const Technical = styled.p`
  font-family: Proxima Nova;
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-technical);
  line-height: 24px;
  letter-spacing: 0.03em;
`;

export const TechnicalSmall = styled.p`
  font-family: Proxima Nova;
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-technical-small);
  line-height: 24px;
  letter-spacing: 0.03em;
`;

export const QuoteLarge = styled(P1)`
  font-style: italic;
`;

export const Quote = styled.p`
  font-family: Proxima Nova;
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-quote);
  line-height: 20px;
  letter-spacing: 0.03em;
  font-style: italic;

  @media (min-width: 750px) {
    line-height: 24px;
  }
`;
