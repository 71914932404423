import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import SiteHeaderDropdownMenu from './siteHeaderDropdown';

// Styled Components
const Nav = styled.nav`
  position: relative;
  display: none;
  perspective: 2000px;

  @media (min-width: 1024px) {
    display: block;
  }
`;

const NavLinks = styled.ul`
  display: flex;
  align-items: flex-end;
`;

const NavLink = styled(Link)`
  padding: 10px 20px;
  color: var(--nav-color);
  font-family: Poppins;
  font-size: 15px;
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: ${({ theme }) => theme.transition.hover};
  cursor: pointer;
  &:hover {
    opacity: 0.6;
  }
`;
// End Styled Components

const SiteHeaderNav = ({ routes, productRoutes, solutionRoutes }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState('Products');

  return (
    <Nav onMouseLeave={() => setDropdownOpen(false)}>
      {/* <Nav> */}
      <NavLinks>
        {routes.map((route) => {
          if (route.title != 'Products' && route.title != 'Solutions') {
            return (
              <li key={route.id}>
                <NavLink
                  to={route.path}
                  onMouseEnter={() => setDropdownOpen(false)}
                >
                  {route.title}
                </NavLink>
              </li>
            );
          } else {
            return (
              <li key={route.id}>
                <NavLink
                  to={route.path}
                  onClick={() => setDropdownOpen(false)}
                  onMouseEnter={() => {
                    setActiveMenu(route.title);
                    setDropdownOpen(true);
                  }}
                >
                  {route.title}
                </NavLink>
              </li>
            );
          }
        })}
      </NavLinks>
      <SiteHeaderDropdownMenu
        isDropdownOpen={isDropdownOpen}
        setDropdownOpen={setDropdownOpen}
        activeMenu={activeMenu}
        productRoutes={productRoutes}
        solutionRoutes={solutionRoutes}
      />
    </Nav>
  );
};

export default SiteHeaderNav;
